.hero__wrapper {
   display: flex;
   justify-content: space-between;
 }
 
 .hero__content,
 .hero__img {
   width: 50%;
 }
 
 .hero__content h2 {
   font-size: 3rem;
   line-height: 65px;
 }
 
 .hero__content p {
   margin-top: 40px;
   font-size: 1.1rem;
 }
 
 .hero__content {
   padding-top: 40px;
 }
 
 .highlight {
   color: var(--primary-color);
 }
 
 .hero__btns {
   margin-top: 40px;
   display: flex;
   align-items: center;
   column-gap: 2rem;
 }
 
 .primary__btn,
 .secondary__btn {
   padding: 0.8rem 1.5rem;
   border: none;
   outline: none;
   color: aliceblue;
   border-radius: 0.4rem;
   cursor: pointer;
 }
 
 .primary__btn {
   background-color: var(--btn-secondary-bg);
   border: 1px solid var(--btn-primary-bg);
 }
 
 .secondary__btn {
   background-color: var(--btn-primary-bg);
   border: 1px solid var(--btn-secondary-bg);
 }
 
 .hero__img img {
   width: 100%;
 }
 
 /* Media Queries for Mobile Responsiveness */
 @media (max-width: 768px) {
   .hero__wrapper {
     flex-direction: column;
     align-items: center;
   }
 
   .hero__content,
   .hero__img {
     width: 100%;
   }
 
   .hero__btns {
     flex-direction: column;
     row-gap: 1rem;
   }
 }
 
 @media (max-width: 480px) {
   .hero__content h2 {
     font-size: 2rem;
     line-height: 45px;
   }
 
   .hero__content p {
     font-size: 1rem;
   }
 
   .primary__btn,
   .secondary__btn {
     width: 100%;
     padding: 0.8rem 1rem;
   }
 }
 