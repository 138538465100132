/* Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

/* CSS Variables */
:root {
  --primary-color: #379cf6;
  --heading-color: #fff;
  --btn-primary-bg: #379cf6;
  --btn-secondary-bg: #090238;
  --team-card-bg: #1b2352;
  --link-color: #fff;
  --link-active: #379cf6;
  --card-bg: linear-gradient(
    45deg,
    rgba(15, 18, 61, 1) 33%,
    rgba(15, 18, 61, 1) 52%,
    rgba(11, 41, 69, 1) 84%
  );
  --counter-color: linear-gradient(
    90deg,
    #02bba6 30%,
    rgba(29, 129, 176, 1) 55%,
    rgba(35, 93, 173, 1) 75%
  );
  --body-bg: rgba(6, 11, 26, 1);
  --newsletter-bg: linear-gradient(
    90deg,
    rgba(27, 9, 115, 1) 20%,
    rgba(23, 9, 96, 1) 38%,
    rgba(14, 9, 56, 1) 100%
  );
  --testimonial-bg: #1a0f4f;
  --font-name: "Roboto", sans-serif;
  --small-text-color: rgba(255, 255, 255, 0.774);
  --icon-bg: #151e4f;
}

.light-theme {
  --body-bg: rgb(255, 255, 255);
  --heading-color: #0c123d;
  --btn-secondary-bg: #009DC4;
  --btn-primary-bg: #fff;
  --primary-color: #009DC4;
  --counter-color: #fff;
  --counter-section-bg: #009DC4;
  --link-color: #0c123d;
  --link-active: #816aff;
  --newsletter-bg: linear-gradient(
    180deg,
    rgba(189, 243, 255, 1) 29%,
    rgba(193, 243, 255, 1) 50%,
    rgba(226, 250, 255, 1) 78%
  );
  --small-text-color: #000;
  --card-bg: #fff;
  --testimonial-bg: #f7f7f7;
  --team-card-bg: #fff;
}

.dark-theme {
  --body-bg: #4D4D4D;
  --heading-color: #fff;
  --btn-primary-bg: #009DC4;
  --btn-secondary-bg: #090238;
  --primary-color: #009DC4;
  --counter-color: linear-gradient(
    90deg,
    #02bba6 30%,
    rgba(29, 129, 176, 1) 55%,
    rgba(35, 93, 173, 1) 75%
  );
  --link-color: #fff;
  --link-active: #379cf6;
  --newsletter-bg: linear-gradient(
    90deg,
    rgba(27, 9, 115, 1) 20%,
    rgba(23, 9, 96, 1) 38%,
    rgba(14, 9, 56, 1) 100%
  );
  --small-text-color: rgba(255, 255, 255, 0.774);
  --card-bg: linear-gradient(
    45deg,
    rgba(15, 18, 61, 1) 33%,
    rgba(15, 18, 61, 1) 52%,
    rgba(11, 41, 69, 1) 84%
  );
  --testimonial-bg: #1a0f4f;
  --team-card-bg: #1b2352;
}

/* Global Styles */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background: var(--body-bg);
  font-family: var(--font-name);
}

a {
  text-decoration: none;
  color: var(--link-color);
}

h1, h2 {
  color: var(--heading-color);
  font-size: 2rem;
}

section {
  padding: 60px 15px; /* Added responsive padding */
}

.container {
  width: 100%;
  max-width: 1140px;
  padding: 0 15px;
  margin: auto;
}

.description {
  color: var(--small-text-color);
  line-height: 30px;
  font-size: .9rem;
}

.subtitle {
  font-size: 1rem;
  color: var(--primary-color);
  font-weight: 400;
  margin-bottom: 20px;
}

.light-theme .counter {
  background: var(--counter-section-bg);
}

.light-theme .secondary__btn {
  border: 1px solid var(--primary-color);
  color: #090238;
  font-weight: 600;
}

.light-theme a {
  font-weight: 500;
}

.light-theme .team__img {
  background: #ddd;
}

/* Responsive Styles */
@media only screen and (min-width: 768px) {
  .container {
    padding: 0 30px; /* Increase padding for larger screens */
  }
}

@media only screen and (min-width: 1024px) {
  .container header .header-nav-area #nav_container {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    padding: 0 10px; /* Adjust padding for smaller screens */
  }

  h1, h2 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  section {
    padding: 30px 15px; /* Adjust padding for smaller screens */
  }
}
