.description__about{
margin-top: 3opx;
}
.about__wrapper{
    display: flex;
    column-gap: 2rem;
}
.about__content,
.about__img{
    width: 50%;
}

.about__img img{
    width: 100%;
    height: 70%;
    border: 1px solid var(--primary-color);
    object-fit: cover;
    border-radius: 0.5rem;
}
.choose__item-wrapper{
    margin-top: 20px;
} 

.choose_us-item{
    padding: 20px;
    display: flex;
    column-gap: 1rem;
    margin-top: 15px;
    align-items: center;
}

.choose__us-icon{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    
}

.choose__us-icon i{
    color: var(--primary-color);
    font-size: 2rem;
}

.choose__us-title{
    color: #fff;
    font-weight: 400;
}

.light-theme .choose__us-title{
    color: #0c123d !important;
} 

@media only screen and (max-width: 1200px) {
    .about__content,
    .about__img {
        width: 100%; /* Stack content and image vertically on smaller screens */
    }

    .about__img img {
        height: auto; /* Maintain aspect ratio */
    }
}

@media only screen and (max-width: 768px) {
    .about__wrapper {
        flex-direction: column; /* Stack content and image vertically on mobile screens */
        align-items: center;
    }

    .about__content {
        order: 1; /* Ensure content comes before the image */
    }

    .about__img {
        order: 2; /* Ensure image comes after the content */
        margin-top: 20px; /* Add space between content and image */
    }

    .choose_us-item {
        padding: 10px; /* Further adjust padding */
        flex-direction: column; /* Stack items vertically */
        text-align: center; /* Center align text */
    }

    .choose__us-icon {
        margin-bottom: 10px; /* Add margin between icon and text */
    }
}