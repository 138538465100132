.slider__content-top {
    text-align: center;
    margin-top: 50px;
}

.slider__wrapper {
    width: 70%;
    margin: auto;
}

.slider__item {
    background: var(--testimonial-bg);
    padding: 40px 30px;
    border-radius: .5rem;
    text-align: center;
    cursor: pointer;
    margin-top: 40px;
}

.customer__details {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    row-gap: 1rem; /* Space between image and text */
    justify-content: center;
    margin-top: 40px;
}

.customer__img {
    width: 15%;
    height: 15%; /* Make height same as width to maintain round shape */
    max-width: 150px; /* Maximum size for large screens */
    max-height: 150px; /* Maximum size for large screens */
    border-radius: 50%; /* Make image round */
    overflow: hidden; /* Ensure content fits inside the rounded container */
}

.customer__img img {
    width: 100%;
    height: 100%;
    border-radius: 50%; /* Ensure image itself is round */
    object-fit: cover;
}

.customer__name {
    font-size: 1.2rem;
    color: #fff;
    font-weight: 300;
}

.light-theme .customer__name {
    color: #0c123d;
    font-weight: 500;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .slider__wrapper {
        width: 90%;
    }

    .customer__details {
        row-gap: 1rem; /* Ensure spacing is maintained in mobile view */
    }

    .customer__img {
        width: 100px;
        height: 100px;
        max-width: 100px; /* Consistent size */
        max-height: 100px; /* Consistent size */
    }

    .slider__item {
        padding: 30px 20px;
    }

    .customer__name {
        font-size: 1rem;
    }

    .slider__content-top h2 {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .slider__wrapper {
        width: 100%;
    }

    .customer__details {
        row-gap: 0.5rem; /* Adjust spacing for very small screens */
    }

    .customer__img {
        width: 80px;
        height: 80px;
        max-width: 80px; /* Consistent size */
        max-height: 80px; /* Consistent size */
    }

    .slider__item {
        padding: 20px 15px;
    }

    .customer__name {
        font-size: 0.9rem;
    }

    .slider__content-top h2 {
        font-size: 1.2rem;
    }

    .slider__content-top {
        margin-top: 30px;
    }

    .slider__item p.description {
        font-size: 0.9rem;
    }
}
