/* WhatsAppButton.css */
.whatsapp-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .whatsapp-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #25d366;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    color: white;
  }
  
  .whatsapp-icon {
    width: 40px;
    height: 40px;
  }
  
  .whatsapp-text {
    margin-top: 5px;
    font-size: 12px;
    color: #25d366;
    font-weight: bold;
    text-align: center;
  }
  
  /* Media query for mobile responsiveness */
  @media (max-width: 768px) {
    .whatsapp-container {
      bottom: 10px;
      right: 10px;
    }
  
    .whatsapp-icon {
      width: 35px;
      height: 35px;
    }
  
    .whatsapp-text {
      font-size: 10px;
    }
  }
  