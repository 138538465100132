/* Light Theme Styles */
.light-theme .clients {
    background: #f0f4f8;
}

.light-theme .clients__title,
.light-theme .clients__description,
.light-theme .client__name {
    color: #333;
}

.light-theme .client__card {
    background-color: #fff;
}

/* Dark Theme Styles */
.clients {
    background: var(--card-bg);
}

.clients__title,
.clients__description,
.client__name {
    color: #fff;
}

.client__card {
    background-color: #17075ee2;
}

.client__card:hover {
    background-color: #616196; /* Optional: Change hover effect for dark mode */
}

/* Common Styles */
.clients {
    padding: 2rem 0;
    text-align: center;
}

.clients__title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.clients__description {
    font-size: 1rem;
    margin-bottom: 2rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.clients__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.client__card {
    width: 150px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease;
}

.client__card:hover {
    transform: scale(1.05);
}

.client__logo {
    width: 100%;
    height: auto;
    max-height: 100px;
    object-fit: contain;
}

.client__name {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0.5rem;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .clients__grid {
        flex-direction: column;
        align-items: center;
    }

    .client__card {
        width: 100%;
    }
}
