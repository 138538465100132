/* Light Theme Styles */
.light-theme .contact {
    background: #f0f4f8;
  }
  
  .light-theme .contact__title,
  .light-theme .contact__description,
  .light-theme .contact__link {
    color: #333;
  }
  
  .light-theme .contact__item h3 {
    color: #17075e;
  }
  
  /* Dark Theme Styles */
  .contact {
    background: var(--card-bg);
    padding: 3rem 0;
  }
  
  .contact__title,
  .contact__description,
  .contact__link {
    color: #fff;
  }
  
  .contact__item h3 {
    color: #ffffffb3;
  }
  
  .contact__item p {
    margin: 0.5rem 0;
  }
  
  /* Common Styles */
  .contact {
    padding: 2rem 0;
    text-align: center;
  }
  
  .contact__title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .contact__description {
    font-size: 1rem;
    margin-bottom: 2rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .contact__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  
  .contact__item h3 {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }
  
  .contact__link {
    font-size: 1rem;
    text-decoration: none;
    color: var(--primary-color);
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .contact__link:hover {
    color: var(--link-hover);
  }
  
  /* Responsive Styles */
  @media only screen and (max-width: 768px) {
    .contact__info {
      flex-direction: column;
    }
  }
  