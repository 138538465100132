.newsletter {
    background: var(--newsletter-bg);
    padding: 2rem 1rem;
}

.newsletter__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.newsletter__content,
.newsletter__form {
    width: 50%;
}

.newsletter__form input {
    padding: 0.8rem 1.5rem;
    border: none;
    outline: none;
    font-size: 1rem;
    cursor: pointer;
    flex: 1;
}

.newsletter__form {
    padding: 0.4rem;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
    width: 100%;
}

.newsletter__form button {
    padding: 0.8rem 1.5rem;
    border: none;
    outline: none;
    font-size: 1rem;
    cursor: pointer;
    background: var(--primary-color);
    color: #fff;
    border-radius: 0.5rem;
    margin-left: 1rem;
}

.light-theme .newsletter {
    background: linear-gradient(180deg, rgba(189, 243, 255, 1) 29%, rgba(193, 243, 255, 1) 50%, rgba(226, 250, 255, 1) 78%);
}

.light-theme .subscribe__btn {
    background: var(--primary-color);
    color: #fff;
}

@media only screen and (max-width: 768px) {
    .newsletter__wrapper {
        flex-direction: column;
    }

    .newsletter__content,
    .newsletter__form {
        width: 100%;
    }

    .newsletter__content {
        margin-bottom: 1rem;
        text-align: center;
    }

    .newsletter__form {
        flex-direction: column;
        align-items: stretch;
    }

    .newsletter__form button {
        margin-left: 0;
        margin-top: 1rem;
        width: 100%;
    }
}
