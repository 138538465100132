.blog__top-content {
    text-align: center;
    margin-bottom: 50px;
}

.blog__item {
    width: 35%;
    padding: 30px 25px;
    background: var(--card-bg);
    border-radius: 0.5rem;
    margin-bottom: 20px;
}

.blog__img img {
    width: 100%;
}

.blog__item h3 {
    color: whitesmoke;
    font-weight: 300;
    font-size: 1.5rem;
    margin-top: 20px;
    cursor: pointer;
}

.blog__img {
    margin-bottom: 30px;
}

.blog_desc {
    font-size: 1.1rem;
    margin-bottom: 10px;
}

.learn_more i {
    font-size: 1.5rem;
    color: var(--primary-color);
    cursor: pointer;
}

.blog__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 2.5rem;
    margin-top: 100px;
}

/* Remove specific margin adjustments for the middle blog item */
.blog__item:nth-child(2) {
    width: 35%; /* Ensure the width is consistent */
    margin-top: 0; /* Remove any top margin adjustments */
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 1024px) {
    .blog__wrapper {
        flex-direction: column;
        align-items: center;
    }

    .blog__item {
        width: 80%;
        margin-bottom: 30px;
    }
}

@media (max-width: 768px) {
    .blog__wrapper {
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
    }

    .blog__item {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .blog__wrapper {
        flex-direction: column;
        align-items: center;
    }

    .blog__item {
        width: 100%; /* Ensure full width on mobile */
    }

    .blog__top-content h2 {
        font-size: 1.5rem;
    }

    .blog__item h3 {
        font-size: 1.2rem;
    }

    .blog_desc {
        font-size: 1rem;
    }

    .learn_more i {
        font-size: 1.2rem;
    }
}
